<!-- SwitchRole Dialog-box -->
<div *ngIf="activity.type == 'switchrole'">
  <div class="container mb-3">
    <div class="row switchRole-heading text-gray-scale-05">
      <div class="col text-center mt-2">
        <p class="b-body-04">{{ roleSwitcherText }}</p>
      </div>

      <div>
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" />
        </button>
      </div>
    </div>

    <div class="role-selection">
      <label class="b-body-06 text-primary-dark-blue-color" id="instruction">{{
        roleSelection
      }}</label>
      <mat-radio-group class="roles bayer-form d-flex" [(ngModel)]="userRole">
        <mat-radio-button
          class="roles-list text-primary-dark-blue-color b-body-06 ml-2"
          *ngFor="let role of roles"
          [value]="role.id"
        >
          {{ role.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="d-flex proceed-btns">
      <div>
        <button
          mat-dialog-close
          class="btn-bayer-primary small mr-4"
          (click)="switchRole(userRole)"
        >
          {{ proceedText }}
        </button>
      </div>

      <div>
        <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
          <u>{{ cancelText }}</u>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Deletion dialog-box -->
<ng-container *ngIf="activity.type == 'delete'">
  <div class="container mb-3">
    <div class="row heading text-gray-scale-05">
      <div class="col text-center mb-1 mt-2 pt-1">
        <p class="b-body-04">{{ deleteFormText }}</p>
      </div>

      <div>
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
        </button>
      </div>
    </div>

    <div class="question">
      <label
        class="b-body-14 mt-4 mb-0 text-primary-dark-blue-color"
        id="confirmation"
        >{{ deleteConfirmation }}</label
      >
    </div>

    <div class="d-flex final-step">
      <div>
        <button class="btn-bayer-primary small mr-4">{{ proceedText }}</button>
      </div>

      <div>
        <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
          {{ cancelText }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<!-- Success dialog-box -->
<div *ngIf="activity.type == 'complete'">
  <ng-container>
    <div class="container mb-3">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ successText }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="question d-flex">
        <img class="tick" src="/assets/icons/approve.svg" alt="" />

        <label class="b-body-14 success-message text-primary-dark-blue-color">{{
          successMessage
        }}</label>
      </div>
    </div>
  </ng-container>
</div>

<!-- Search dialog-box -->
<ng-container *ngIf="activity.type == 'searchBoxMTMSelection'">
  <div class="container mb-3 many-to-many-object-relation-dialog-cont">
    <div class="row heading text-gray-scale-05">
      <div class="col float-end">
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
        </button>
      </div>
    </div>

    <div class="search-box">
      <input
        placeholder="Local Search"
        type="text"
        (keyup)="searchData($event)"
      />

      <button class="btn-search mr-3" (click)="searchData($event)">
        <img class="b-icon" src="/assets/icons/search-white.svg" alt="" />
      </button>

      <input
        placeholder="Global Search"
        type="text"
        [(ngModel)]="apiSearchText"
      />

      <button class="btn-search" (click)="searchAPIData()">
        <img class="b-icon" src="/assets/icons/search-white.svg" alt="" />
      </button>
    </div>

    <div class="container mt-3" *ngIf="isSearhBox">
      <app-shimmer [shimmerType]="shimmerType[0]"></app-shimmer>
    </div>

    <div class="many-to-many-table">
      <table mat-table [dataSource]="searchdataSource" *ngIf="!isSearhBox">
        <ng-container *ngFor="let col of searchdisplayedColumns">
          <ng-container matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef class="b-body-17">
              <ng-container *ngIf="col == 'Checkbox'">
                <mat-checkbox
                  class="check-box-tick"
                  [checked]="selectAllMTM"
                  (change)="selectAllMTMField()"
                ></mat-checkbox>
              </ng-container>

              <ng-container *ngIf="col != 'Checkbox'">{{ col }}</ng-container>
            </th>
            <td mat-cell *matCellDef="let orderList; let i = index">
              <div *ngIf="col == 'Checkbox'; else rowData" class="bayer-form">
                <mat-checkbox
                  class="check-box-tick"
                  [checked]="orderList[col]"
                  (change)="
                    orderList[col] = !orderList[col];
                    selectDataMTM(orderList, $event)
                  "
                ></mat-checkbox>
              </div>

              <ng-template #rowData>
                <div class="text-primary-dark-blue-color">
                  {{ orderList[col] }}
                </div>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="searchdisplayedColumns"></tr>

        <tr
          mat-row
          *matRowDef="let orderList; columns: searchdisplayedColumns"
        ></tr>
      </table>

      <div
        class="text-center mb-5 b-heading-03 text-primary-dark-blue-color"
        *ngIf="message"
      >
        {{ message }}
      </div>
    </div>

    <div class="pagination-cont justify-content-end">
      <button (click)="previousPage()" [disabled]="tableOffset <= 1">
        <img src="/assets/icons/arrow-left-white.svg" alt="" />
      </button>

      <div class="page-count">
        <div>
          <input
            type="number"
            min="1"
            value="{{ tableOffset }}"
            max="{{ tableLastPage }}"
            (change)="updatePageMTM($event)"
            class="b-body-07"
          />
        </div>

        <div class="total-page b-body-07 text-primary-dark-color">
          of {{ tableLastPage }}
        </div>
      </div>

      <button (click)="nextPageMTM()" [disabled]="tableOffset >= tableLastPage">
        <img src="/assets/icons/arrow-right-white.svg" alt="" />
      </button>
    </div>

    <div class="next">
      <button class="btn-bayer-secondary mr-4" mat-dialog-close>
        {{ cancelText }}
      </button>

      <button
        class="btn-bayer-primary small"
        [mat-dialog-close]="finalList"
        (click)="displayData()"
        [disabled]="finalList.length === 0"
      >
        {{ addText }}
      </button>
    </div>
  </div>
</ng-container>

<!-- Search dialog-box (only one selection) -->
<ng-container *ngIf="activity.type == 'searchBoxOneSelection'">
  <div class="container mb-3 many-to-one-cont">
    <div class="row heading text-gray-scale-05">
      <div class="col float-end">
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="search-box">
      <div
        class="class-name-selector-cont mr-3"
        *ngIf="mt1MultiClassName.length > 0"
      >
        <mat-form-field
          class="bayer-form filter-form w-100 b-body-09 text-primary-dark-blue-color"
          appearance="fill"
        >
          <mat-select
            class="b-body-09 select"
            [(value)]="selectedMt1MultiClassName"
            (selectionChange)="getMT1DropValue()"
            placeholder="Select Class Name..."
          >
            <div>
              <mat-option
                class="b-body-09 option-text"
                *ngFor="let className of mt1MultiClassName"
                value="{{ className }}"
                id="className"
                >{{ className }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container>
        <input
          placeholder="What are you looking for?"
          type="text"
          (keyup)="searchData($event)"
          [disabled]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
          "
          [ngClass]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
              ? 'disabled'
              : ''
          "
        />

        <button
          class="btn-search"
          (click)="searchData($event)"
          [disabled]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
          "
          [ngClass]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
              ? 'disabled'
              : ''
          "
        >
          <img class="b-icon" src="/assets/icons/search-white.svg" alt="" />
        </button>

        <input
          placeholder="Global Search"
          type="text"
          [(ngModel)]="apiSearchTextMT1"
          [disabled]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
          "
          class="ml-3"
          [ngClass]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
              ? 'disabled'
              : ''
          "
        />

        <button
          class="btn-search"
          (click)="searchAPIDataMT1()"
          [disabled]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
          "
          [ngClass]="
            !isSearhBox &&
            mt1MultiClassName.length > 0 &&
            selectedMt1MultiClassName === ''
              ? 'disabled'
              : ''
          "
        >
          <img class="b-icon" src="/assets/icons/search-white.svg" alt="" />
        </button>
      </ng-container>
    </div>

    <div
      class="mx-auto class-name-img-cont text-center mt-4"
      *ngIf="mt1MultiClassName.length > 0 && !isSearhBox && !searchdataSource"
    >
      <img
        src="./../../../../../assets/icons/translation-dashboard-img.svg"
        alt=""
      />
      <p class="b-body-09 text-primary-dark-blue-color mt-3">
        Select classname to get content list
      </p>
    </div>

    <div class="container mt-3" *ngIf="isSearhBox">
      <app-shimmer [shimmerType]="shimmerType[0]"></app-shimmer>
    </div>

    <div class="many-to-one-table">
      <table mat-table [dataSource]="searchdataSource" *ngIf="!isSearhBox">
        <ng-container *ngFor="let col of searchdisplayedColumns">
          <ng-container matColumnDef="{{ col }}">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="b-body-17 text-primary-dark-blue-color"
            >
              <ng-container *ngIf="col != 'Checkbox'">{{ col }}</ng-container>
            </th>

            <th
              mat-header-cell
              *matHeaderCellDef
              class="b-body-17 text-primary-dark-blue-color"
            >
              <ng-container>{{ col }}</ng-container>
            </th>

            <td mat-cell *matCellDef="let searchList; let i = index">
              <div *ngIf="col == 'Checkbox'; else rowData" class="bayer-form">
                <mat-checkbox
                  class="check-box-tick"
                  [checked]="searchList[col]"
                  (change)="
                    searchList[col] = !searchList[col];
                    selectDataMT1(searchList, $event, searchList[col])
                  "
                ></mat-checkbox>
              </div>

              <ng-template #rowData>
                <div class="text-primary-dark-blue-color">
                  {{ searchList[col] }}
                </div>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="searchdisplayedColumns"></tr>

        <tr
          mat-row
          *matRowDef="let searchList; columns: searchdisplayedColumns"
        ></tr>
      </table>

      <div
        class="text-center my-5 b-heading-03 text-primary-dark-blue-color"
        *ngIf="message"
      >
        {{ message }}
      </div>
    </div>

    <div
      class="pagination-cont justify-content-end"
      *ngIf="!isSearhBox && searchdataSource"
    >
      <button (click)="previousPage()" [disabled]="tableOffset <= 1">
        <img src="/assets/icons/arrow-left-white.svg" alt="" />
      </button>

      <div class="page-count">
        <div>
          <input
            type="number"
            min="1"
            value="{{ tableOffset }}"
            max="{{ tableLastPage }}"
            (change)="updatePage($event)"
            class="b-body-07"
          />
        </div>

        <div class="total-page b-body-07 text-primary-dark-color">
          of {{ tableLastPage }}
        </div>
      </div>

      <button (click)="nextPage()" [disabled]="tableOffset >= tableLastPage">
        <img src="/assets/icons/arrow-right-white.svg" alt="" />
      </button>
    </div>

    <div class="next">
      <button class="btn-bayer-secondary mr-4" mat-dialog-close>
        {{ cancelText }}
      </button>

      <button
        class="btn-bayer-primary small"
        [mat-dialog-close]="searchedList"
        (click)="selectedData()"
        [disabled]="searchedList.length === 0"
      >
        {{ addText }}
      </button>
    </div>
  </div>
</ng-container>

<!-- View Media DA -->
<ng-container *ngIf="activity.type == 'viewMedia'">
  <div class="container mb-3 many-to-one-cont">
    <div class="row heading text-gray-scale-05">
      <div class="col float-end">
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
        </button>
      </div>
    </div>

    <div class="search-box">
      <input
        placeholder="What are you looking for?"
        type="text"
        (keyup)="searchData($event)"
      />
      <button class="btn-search" (click)="searchData($event)">
        <img class="b-icon" src="/assets/icons/search-white.svg" alt="" />
      </button>
    </div>

    <div class="container mt-3" *ngIf="isSearhBox">
      <app-shimmer [shimmerType]="shimmerType[0]"></app-shimmer>
    </div>

    <div class="many-to-one-table">
      <table mat-table [dataSource]="searchdataSource" *ngIf="!isSearhBox">
        <ng-container *ngFor="let col of searchdisplayedColumns">
          <ng-container matColumnDef="{{ col }}">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="b-body-17 text-primary-dark-blue-color"
            >
              <ng-container
                *ngIf="col != 'Checkbox' && col != 'Link' && col != 'Delete'"
              >
                {{ col }}
              </ng-container>
            </th>

            <td mat-cell *matCellDef="let searchList; let index = index">
              <div
                *ngIf="
                  col == 'Checkbox' || col == 'Link' || col == 'Delete';
                  else rowData
                "
                class="bayer-form b-body-09 text-primary-dark-blue-color"
              >
                <div
                  *ngIf="col == 'Link'"
                  class="pe-auto"
                  (click)="openInNewTab(searchList['id'], searchList['Key'])"
                  mat-dialog-close
                >
                  <img class="link-icon" src="/assets/icons/link.svg" alt="" />
                </div>

                <div
                  *ngIf="col == 'Delete'"
                  class="pe-auto"
                  (click)="deleteTableData(index)"
                >
                  <img
                    class="delete-icon"
                    src="/assets/icons/close.svg"
                    alt=""
                  />
                </div>
              </div>

              <ng-template #rowData>
                <div class="text-primary-dark-blue-color">
                  {{ searchList[col] }}
                </div>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="searchdisplayedColumns"></tr>

        <tr
          mat-row
          *matRowDef="let searchList; columns: searchdisplayedColumns"
        ></tr>
      </table>

      <div
        class="text-center mb-5 b-heading-03 text-primary-dark-blue-color"
        *ngIf="message"
      >
        {{ message }}
      </div>
    </div>

    <div class="next">
      <button class="btn-bayer-secondary mr-4" mat-dialog-close>
        {{ cancelText }}
      </button>

      <button
        class="btn-bayer-primary small"
        [mat-dialog-close]="viewMediaTable"
      >
        Update
      </button>
    </div>
  </div>
</ng-container>

<!-- Validation Error dialog-box -->
<div *ngIf="activity.type == 'warning'">
  <ng-container>
    <div class="container mb-4">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ warningText }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="errorMessage d-flex">
        <img class="warning" src="/assets/icons/warning.svg" alt="" />

        <label class="b-body-14 error-message text-primary-dark-blue-color">{{
          activity.value.text
        }}</label>
      </div>

      <div
        class="d-flex justify-content-center align-items-center mt-4"
        *ngIf="!activity.value.button.type"
      >
        <button class="btn-bayer-primary" mat-dialog-close>
          {{ activity.value.button.data[0] }}
        </button>
      </div>

      <div
        class="d-flex justify-content-center align-items-center mt-4"
        *ngIf="activity.value.button.type"
      >
        <button class="btn-bayer-secondary mr-4" mat-dialog-close>
          {{ activity.value.button.data[0] }}
        </button>

        <button class="btn-bayer-primary" [mat-dialog-close]="true">
          {{ activity.value.button.data[1] }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<!-- Validation Error dialog-box -->
<div *ngIf="activity.type == 'error'">
  <ng-container>
    <div class="container mb-4">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ validationFailedText }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="errorMessage d-flex">
        <img class="warning" src="/assets/icons/warning.svg" alt="" />

        <label class="b-body-14 error-message text-primary-dark-blue-color">{{
          errorMessage
        }}</label>
      </div>

      <div class="errorValidationSteps">
        <div *ngFor="let item of activity.value">
          <li class="steps b-body-09 text-primary-dark-blue-color">
            {{ item }}
          </li>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Asset Error dialog box-->
<div *ngIf="activity.type == 'assetDialog'">
  <ng-container>
    <div class="container mb-4">
      <div class="row asset heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ activity.value.text }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="errorMessage d-flex">
        <img
          class="tick"
          *ngIf="!activity.value.success"
          src="/assets/icons/warning.svg"
          alt=""
        />

        <img
          class="tick"
          *ngIf="activity.value.success"
          src="/assets/icons/approve.svg"
          alt=""
        />

        <label
          class="b-body-14 asset-error-message text-primary-dark-blue-color"
          >{{ activity.value.message }}</label
        >
      </div>

      <div class="errorValidationSteps">
        <div *ngFor="let item of activity.value.fileNames">
          <li class="steps b-body-09 text-primary-dark-blue-color">
            {{ item }}
          </li>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Utility dialog box-->
<div *ngIf="activity.type == 'utilityDialog'" class="utilityDialog">
  <ng-container>
    <div class="container mb-4">
      <div class="row asset heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ activity.value.title }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="utility-message">
        <label
          *ngIf="!utilityNoResults"
          class="b-body-14 text-primary-dark-blue-color"
          >{{ selectValuesCautionText }}</label
        >

        <label
          *ngIf="utilityNoResults"
          class="b-body-14 text-primary-dark-blue-color"
          >{{ noContentsFoundText }}</label
        >
      </div>

      <div *ngIf="!utilityNoResults">
        <section class="utility-checkbox text-primary-dark-blue-color">
          <mat-checkbox [disabled]="true" [checked]="true">{{
            countryText
          }}</mat-checkbox>

          <mat-checkbox [(ngModel)]="isCropChecked">{{
            cropText
          }}</mat-checkbox>

          <mat-checkbox [(ngModel)]="isCropStageChecked">{{
            cropStageText
          }}</mat-checkbox>
        </section>
      </div>

      <div
        class="utility-message error"
        *ngIf="utilityError && !utilityNoResults"
      >
        <label class="b-body-14 text-bayer-red">{{ formFillCaution }} </label>
      </div>

      <div *ngIf="!utilityNoResults" class="utility-button">
        <button
          class="btn-bayer-primary small b-body-08 ml-3"
          (click)="utilityProcessing(activity.value)"
        >
          {{ process }}
        </button>

        <button class="btn-bayer-secondary ml-3" mat-dialog-close>
          {{ cancelText }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<!-- Initiate Translation dialog box -->
<div *ngIf="activity.type == 'translate'">
  <div class="container mb-3">
    <div class="row heading text-gray-scale-05">
      <div class="col text-center mt-3">
        <p class="b-body-04">{{ initiateTranslationText }}</p>
      </div>

      <div>
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" />
        </button>
      </div>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-6">
      <label
        class="b-body-01 form-label text-primary-dark-blue-color"
        style="margin-left: -14px"
        >{{ translationLanguages }}</label
      >

      <div class="contents mt-2">
        <div *ngIf="dropdownShimmer">
          <app-shimmer [shimmerType]="shimmerType[2]"></app-shimmer>
        </div>

        <mat-form-field
          class="bayer-form dropDown"
          appearance="fill"
          *ngIf="!dropdownShimmer"
        >
          <mat-select
            class="b-body-09 select"
            [(value)]="selectedValue"
            (selectionChange)="onChange()"
            multiple
            placeholder="Select language..."
            (closed)="onClose()"
            (click)="method()"
          >
            <div>
              <mat-option
                class="b-body-09 option-text"
                *ngFor="let language of languages"
                value="{{ language }}"
                id="language"
                >{{ language }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="showTable && dataList.length != 0">
      <hr *ngIf="dataList.length != 0" />

      <div
        class="translation_table"
        *ngIf="dataList.length != 0 && isCountry.length > 0"
      >
        <table class="table">
          <thead class="light">
            <th *ngFor="let col of displayColumns" class="b-body-17">
              <ng-container>{{ col }}</ng-container>
            </th>
          </thead>

          <tr *ngFor="let data of dataList; let i = index">
            <td style="border: 0">
              <div class="row">
                <div class="col-sm-4 row1 language">{{ data }}</div>

                <div
                  *ngIf="text1 == 'Processing' || errorArr.length > 0"
                  class="col-sm-4 progress"
                >
                  <div
                    class="circle"
                    [ngClass]="
                      text1 == 'Processing'
                        ? 'background-primary-mid-dark-color'
                        : 'background-bayer-white'
                    "
                  ></div>

                  <div *ngIf="text1 == 'Processing'">
                    <mat-progress-spinner
                      diameter="16"
                      class="progress_spinner"
                      mode="indeterminate"
                      value="100"
                    ></mat-progress-spinner>
                    <p style="margin: -10px 2px 0 5px">{{ processingText }}</p>
                  </div>

                  <div
                    *ngIf="errorArr[i] == 'Success' && text1 != 'Processing'"
                  >
                    <img
                      class="progress_img"
                      src="/assets/icons/tick-green.svg"
                      alt=""
                    />
                    <p style="margin: -10px 2px 0 8px">{{ errorArr[i] }}</p>
                  </div>

                  <div *ngIf="errorArr[i] == 'Error' && text1 != 'Processing'">
                    <img
                      class="progress_img"
                      src="/assets/icons/warning-red.svg"
                      alt=""
                    />
                    <p style="margin: -10px 2px 0 8px">{{ errorArr[i] }}</p>
                  </div>
                </div>
              </div>
            </td>

            <td style="border: 0">
              <div *ngIf="isCountry[i]">
                <app-shimmer [shimmerType]="shimmerType[2]"></app-shimmer>
              </div>

              <mat-form-field
                class="bayer-form countryDropdown"
                appearance="fill"
                *ngIf="!isCountry[i]"
              >
                <mat-select
                  class="b-body-09 select"
                  [value]="selectedCountry"
                  name="selectedCountry"
                  placeholder="Select country..."
                  (selectionChange)="selectCountry(i, $event)"
                >
                  <div>
                    <mat-option
                      class="body-text mat-option"
                      *ngFor="let country of countryArr[i]"
                      value="{{ country.id }}"
                      >{{ country.AliasName }}</mat-option
                    >
                  </div>
                </mat-select>
              </mat-form-field>
            </td>

            <td style="border: 0">
              <input
                class="content_name"
                type="text"
                placeholder="Name"
                [(ngModel)]="contentName[i]"
                (keyup)="onKeyup(i)"
              />
            </td>
          </tr>
        </table>
      </div>

      <hr *ngIf="dataList.length != 0" />

      <div class="d-flex mx-4 mt-4">
        <div>
          <button
            class="btn-bayer-primary mr-4"
            style="margin-left: -18px"
            (click)="createContent()"
            [disabled]="isContent"
          >
            {{ createContentText }}
          </button>
        </div>

        <div>
          <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Initiate Submit Comment dialog box -->
<div *ngIf="activity.type == 'submitComment'">
  <div class="container mb-3">
    <div class="row heading text-gray-scale-05">
      <div class="col text-center mt-3">
        <p class="b-body-04">{{ commentBoxText }}</p>
      </div>

      <div>
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" />
        </button>
      </div>
    </div>

    <div class="col-12">
      <label class="form-label">{{ notesText }}</label>

      <div class="mt-2 form-group">
        <mat-form-field class="bayer-form text-area w-100" appearance="fill">
          <textarea
            matInput
            placeholder="Type here.."
            [(ngModel)]="submitComment"
          ></textarea>
        </mat-form-field>

        <mat-error *ngIf="message != ''">
          {{ message }}
        </mat-error>
      </div>
    </div>

    <div class="d-flex mx-3 mt-4">
      <div>
        <button class="btn-bayer-primary mr-4" (click)="formSubmit()">
          Submit
        </button>
      </div>

      <div>
        <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
          {{ cancelText }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="activity.type == 'variantsList'">
  <ng-container>
    <div class="container mb-4">
      <div class="row asset heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ activity.value.title }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="many-to-many-table variant-list">
        <table class="col-12 my-3" mat-table [dataSource]="dataSource" matSort>
          <ng-container *ngFor="let col of variantdisplayedColumns">
            <ng-container matColumnDef="{{ col }}">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="{{ col }}"
                class="b-body-17 text-primary-dark-blue-color"
              >
                <ng-container *ngIf="col != 'Link' && col != 'Delete'">
                  {{ col }}
                </ng-container>
              </th>

              <td mat-cell *matCellDef="let orderList; let index = index">
                <div
                  *ngIf="col == 'Link' || col == 'Delete'; else rowData"
                  class="b-body-09 text-primary-dark-color"
                >
                  <div
                    *ngIf="col == 'Link'"
                    (click)="
                      openVariantNewTab(orderList['id'], orderList['Key'])
                    "
                  >
                    <img class="b-icon" src="/assets/icons/link.svg" alt="" />
                  </div>
                </div>

                <ng-template #rowData>
                  <ng-container>
                    {{ orderList[col] }}
                  </ng-container>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="variantdisplayedColumns"></tr>

          <tr
            mat-row
            *matRowDef="let orderList; columns: variantdisplayedColumns"
          ></tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>

<!-- call button dialog box -->
<div *ngIf="activity.type == 'callButton'">
  <ng-container>
    <div class="container mb-4">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mt-3">
          <p class="b-body-04">{{ editLinkText }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close" (click)="close()">
            <img class="close-img" src="/assets/icons/close-white.svg" />
          </button>
        </div>
      </div>

      <div class="contents mt-3 mb-3">
        <div class="row">
          <div class="col-sm-2">
            <label class="form-label text">{{ textText }}</label>
          </div>

          <div class="col-sm-4">
            <mat-form-field
              class="input bayer-form textBox-width"
              appearance="fill"
            >
              <input matInput #text [ngModel]="callButtonObj['text']" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <label class="form-label text">{{ pathText }}</label>
          </div>

          <div class="col-sm-4">
            <mat-form-field
              class="input bayer-form textBox-width"
              appearance="fill"
            >
              <input matInput #path [ngModel]="callButtonObj['path']" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2">
            <label class="form-label text">{{ titleText }}</label>
          </div>

          <div class="col-sm-4">
            <mat-form-field
              class="input bayer-form textBox-width"
              appearance="fill"
            >
              <input matInput #title [ngModel]="callButtonObj['title']" />
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex mx-4 mt-4">
          <div class="save-button">
            <button
              class="btn-bayer-primary small b-body-08 ml-3"
              (click)="save(text.value, path.value, title.value)"
              [disabled]="isContent"
            >
              {{ saveText }}
            </button>

            <button
              mat-dialog-close
              class="b-body-08 btn-bayer-secondary ml-3"
              (click)="close()"
            >
              {{ cancelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Menu Rename Dialog box-->
<div *ngIf="activity.type == 'menuRename'">
  <div class="container mb-3">
    <div class="row heading text-gray-scale-05">
      <div class="col text-center mt-3">
        <p class="b-body-04">{{ renameText }}</p>
      </div>

      <div>
        <button mat-dialog-close class="close">
          <img class="close-img" src="/assets/icons/close-white.svg" />
        </button>
      </div>
    </div>

    <div class="col-12">
      <label class="form-label">{{ nameText }}</label>

      <div class="mt-2 form-group">
        <mat-form-field class="input bayer-form w-100" appearance="fill">
          <input
            matInput
            placeholder="Type here.."
            [(ngModel)]="submitRename"
          />
        </mat-form-field>

        <mat-error *ngIf="message != ''">
          {{ message }}
        </mat-error>
      </div>
    </div>

    <div class="d-flex mx-3 mt-4">
      <div>
        <button class="btn-bayer-primary mr-4" (click)="renameSubmit()">
          {{ submitText }}
        </button>
      </div>

      <div>
        <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
          {{ cancelText }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--Logout Message Dialog Box-->
<div *ngIf="activity.type == 'logout'">
  <ng-container>
    <div class="container mb-4">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ logoutText }}</p>
        </div>

        <div>
          <button (click)="cancelExtendSession()" class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="errorMessage d-flex">
        <img class="warning" src="/assets/icons/warning.svg" alt="" />

        <label class="b-body-14 error-message text-primary-dark-blue-color">{{
          logoutWarningMessage
        }}</label>
      </div>

      <div class="d-flex mx-3 mt-4">
        <div>
          <button class="btn-bayer-primary mr-4" (click)="extendSession()">
            {{ extendSessionText }}
          </button>
        </div>

        <div>
          <button
            mat-dialog-close
            class="b-body-08 btn-bayer-secondary mt-2"
            (click)="cancelExtendSession()"
          >
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Update to all Variant warning Dialog Box-->
<div *ngIf="activity.type == 'updateToAllVariantWarning'">
  <ng-container>
    <div class="container mb-4">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ warningText }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="errorMessage d-flex">
        <img class="warning" src="/assets/icons/warning.svg" alt="" />

        <label class="b-body-14 error-message text-primary-dark-blue-color"
          >Existing values
          <b>will be overridden for the selected attributes of all variants</b>.
          Do you want to proceed?</label
        >
      </div>

      <div class="d-flex align-item-center justify-content-center mx-3 mt-4">
        <div>
          <button
            class="btn-bayer-primary mr-4"
            (click)="UpdateToAllVariantWarning()"
          >
            {{ yesText }}
          </button>
        </div>

        <div>
          <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
            {{ noText }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!--Video Dialog Box-->
<div
  *ngIf="
    activity.type == 'Image' ||
    activity.type == 'Video' ||
    activity.type == 'Youtube'
  "
>
  <ng-container>
    <div class="container mb-2">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ activity.type }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close mr-2">
            <img class="close-img" src="/assets/icons/close-white.svg" />
          </button>
        </div>
      </div>

      <div *ngIf="activity.type == 'Youtube'" class="gallery mt-2">
        <iframe
          width="90%"
          height="400"
          [src]="urlSafe"
          allowfullscreen="allowfullscreen"
        >
        </iframe>
      </div>

      <div *ngIf="activity.type == 'Video'" class="gallery mt-2">
        <video controls width="90%" height="400">
          <source [src]="urlSafe" type="video/mp4" />
        </video>
      </div>

      <div
        *ngIf="activity.type == 'Image'"
        style="height: 400px"
        class="gallery mt-2"
      >
        <img
          style="height: 100%; object-fit: contain; max-width: 100%"
          alt="most view image"
          [src]="urlSafe"
        />
      </div>
    </div>
  </ng-container>
</div>

<!-- Update on all Variant Dialog box -->
<div *ngIf="activity.type == 'updateToAllVariant'">
  <ng-container>
    <div class="container mb-4 updateToAllVariant-cont">
      <div class="row heading text-gray-scale-05">
        <div class="col text-center mb-1 mt-2 pt-1">
          <p class="b-body-04">{{ updateOnAllVariant }}</p>
        </div>

        <div>
          <button mat-dialog-close class="close">
            <img class="close-img" src="/assets/icons/close-white.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="d-flex w-100 flex-column p-3">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <p class="mb-0">{{ selectAttributeVariantsText }}</p>
          </div>

          <div class="selectall-checkbox-cont">
            <mat-checkbox
              (change)="selectAllUpdateToAllVariant($event.checked, activity)"
              ><span class="b-body-06 text-primary-dark-color">{{
                selectAll
              }}</span></mat-checkbox
            >
          </div>
        </div>

        <div class="update-cont d-flex flex-wrap flex-row">
          <div
            *ngFor="let item of activity.updateVariantList; let i = index"
            class="col-6 checkbox-cont"
          >
            <mat-checkbox
              [checked]="item.selected"
              (change)="item.selected = !item.selected"
              ><span class="b-body-06 text-primary-dark-color">{{
                item.attribute
              }}</span></mat-checkbox
            >
          </div>
        </div>
      </div>

      <div></div>
      <div class="d-flex mx-3 align-item-center justify-content-center mt-4">
        <div>
          <button
            class="btn-bayer-primary mr-4"
            (click)="UpdateToAllVariant(activity.updateVariantList)"
          >
            {{ updateText }}
          </button>
        </div>

        <div>
          <button mat-dialog-close class="b-body-08 btn-bayer-secondary mt-2">
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
