import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/** Component which holds SwitchroleService functionality */
@Injectable({
  providedIn: 'root'
})
export class SwitchroleService {

  private userRoleSubject = new Subject<string>();

  /** Function which handles setUserRole
   * @param setUserRole
   */
  setUserRole(val: string): void {
    this.userRoleSubject.next(val);
  }
  
  /** Function that handles getUserRole */
  getUserRole(): Observable<string> {
    return this.userRoleSubject.asObservable();
  }
}
