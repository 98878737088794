import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';

import { ContentTransLanguageRequest } from '../../models/Translation/content-translanguage-request';
import { GlobalValuesService } from '../global-values/global-values.service';

/** Component which holds TranslanguageService */
@Injectable({
  providedIn: 'root'
})

/** Component which holds TranslanguageService */
export class TranslanguageService {
  /** Constructor which holds httpclient */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  data = [];
  transLanguageUrl: string =
    this.globalValue.creatorBaseURL + 'content/translanguage';

  /** function is to get transLanguage */
  gettransLanguage(params): Observable<ContentTransLanguageRequest> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.get<ContentTransLanguageRequest>(this.transLanguageUrl, {
      params: params,
      headers: headers
    });
  }

  /** function is to get send Data */
  sendData(response: string[], str1: string) {
    this.data = [];
    this.data.push(response);
    this.data.push([str1]);
  }
  
  /** function is to get get Data */
  getData() {
    return this.data;
  }
}
