import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { curatorText } from 'src/app/content-curator/curator.messages';

/**
 * success error pop up component
 * @export
 * @class SuccessErrorPopupComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-success-error-popup',
  templateUrl: './success-error-popup.component.html',
  styleUrls: ['./success-error-popup.component.scss']
})

/** Component which holds pop up open depends on success error functionalities when button is clicked*/
export class SuccessErrorPopupComponent implements OnInit {
  success = curatorText.success;
  error = curatorText.error;

  /**
   * Constructor that Injectes MAT_DIALOG_DATA to get data from other component
   * @param msg {dict} messages from other component
   * Variable msg holds the data
   */
  constructor(@Inject(MAT_DIALOG_DATA) public msg) {}

  /** ngOnInit lifecycle hook */
  ngOnInit(): void { 
    // do nothing. 
  }
}
