import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';
import {
  ContentSaveParams,
  ContentSaveResponse
} from '../../models/template/content/content-save.response';
import { GlobalValuesService } from '../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})
export class SaveTemplateService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  saveTemplateUrl: string = this.globalValue.creatorBaseURL + 'content/save';

  /** Function to handle getTemplate */
  saveTemplate(template: ContentSaveParams): Observable<ContentSaveResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.post<ContentSaveResponse>(this.saveTemplateUrl, template, {
      headers
    });
  }

  masterDetailUrl: string =
    this.globalValue.creatorBaseURL + 'master/detail?masterId=';

  /** Function to handle Get master Detail */
  masterDetail(id: number) {
    return this.http.get(this.masterDetailUrl + id);
  }
}
