import { Component, Input, OnInit } from '@angular/core';

/** Component which holds shimmer on UI load */
@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss']
})

/** Class which holds shimmer on UI load */
export class ShimmerComponent implements OnInit {
  @Input() shimmerType = '';
  constructor() {
    // not in use
  }
  tenBlocks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  sixBlocks = [1, 2, 3, 4, 5, 6];
  images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  twoBlocks = [1, 2];
  treeData = [1, 2, 3, 4, 5, 6];
  fourBlocks = [1, 2, 3, 4];
  fiveBlocks = [1, 2, 3, 4, 5];

  /** ngOninit lifecycle hook */
  ngOnInit(): void {
    // not in use
  }
}
