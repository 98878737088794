import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable, Subject } from 'rxjs';
import { ContentHierarchyResponse } from '../../models/menu/content-hierarchy/content-hierarchy.response';
import { ContentListResponse } from '../../models/menu/content-list/content-list.response';
import { GetHierarchyResponse } from '../../models/menu/get-hierarchy/get-hierarchy.response';
import { CreateVariantRequest } from '../../models/menu/variant/create-variant/create-variant.request';
import { CreateVariantResponse } from '../../models/menu/variant/create-variant/create-variant.response';
import { VariantListResponse } from '../../models/menu/variant/variant-list/variant-list.response';
import { ContentSaveResponseResponse } from '../../models/template/content/content-save.response';
import { renameDetails } from '../../models/template/dynamicRendering.model';
import { MasterListDataList } from '../../models/template/many-to-one-relation/many-to-one-relation';
import { GlobalValuesService } from '../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})

/** Class which holds MenuService functionality */
export class MenuService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   * @param {SessionStorageService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  private renameUpdateAutoSaveEnabledSubject = new Subject<ContentSaveResponseResponse>();
  private langSubject = new Subject<MasterListDataList>();

  menuContentUrl: string =
    this.globalValue.creatorBaseURL + 'content/hierarchy?className=';

  /** Function to handle menuContent
   * @param menu
   */
  menuContentHierarchy(menu: string): Observable<ContentHierarchyResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.get<ContentHierarchyResponse>(this.menuContentUrl + menu, {
      headers
    });
  }

  menuContentListUrl: string = this.globalValue.creatorBaseURL + 'content/list';

  /** Function to handle menuContentList
   * @param params
   */
  menuContentList(params): Observable<ContentListResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.get<ContentListResponse>(this.menuContentListUrl, {
      headers: headers,
      params: params
    });
  }

  menuCreateVariantUrl: string =
    this.globalValue.creatorBaseURL + 'variant/create';

  /** Function to handle menuContentList
   * @param params
   */
  menuCreateVariant(
    data: CreateVariantRequest
  ): Observable<CreateVariantResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.post<CreateVariantResponse>(
      this.menuCreateVariantUrl,
      data,
      {
        headers: headers
      }
    );
  }

  menuVariantListUrl: string =
    this.globalValue.creatorBaseURL + 'variant/list?parentId=';

  /** Function to handle menuContentList
   * @param params
   */
  menuVariantList(id: number): Observable<VariantListResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.get<VariantListResponse>(this.menuVariantListUrl + id, {
      headers: headers
    });
  }

  menuGetHierarchyUrl: string =
    this.globalValue.creatorBaseURL + 'get-hierarchy';

  /** Function to handle menuContentList */
  menuGetHierarchy(): Observable<GetHierarchyResponse> {
    return this.http.get<GetHierarchyResponse>(this.menuGetHierarchyUrl);
  }

  public renameAutoSaveEnabledSubject = new Subject<renameDetails>();

  /** Function to handle setMenuItemInfo */
  setRenameAutoSaveEnabledSubject(val: renameDetails): void {
    this.renameAutoSaveEnabledSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getRenameAutoSaveEnabledSubject(): Observable<renameDetails> {
    return this.renameAutoSaveEnabledSubject.asObservable();
  }

  /** Function to handle setMenuItemInfo */
  setRenameUpdateSaveEnabledSubject(val: ContentSaveResponseResponse): void {
    this.renameUpdateAutoSaveEnabledSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getRenameUpdateSaveEnabledSubject(): Observable<ContentSaveResponseResponse> {
    return this.renameUpdateAutoSaveEnabledSubject.asObservable();
  }

  /** Function to handle setMenuItemInfo */
  setLangItemInfo(val: MasterListDataList): void {
    this.langSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getLangtemInfo(): Observable<MasterListDataList> {
    return this.langSubject.asObservable();
  }
}
