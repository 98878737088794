/** Class which holds WebScrapperRequest */
export class WebScrapperRequest {}

/** Interface which holds WebScrapperRequest */
export interface WebScrapperRequest {
  contentType: string[];
  country: string;
  crop: string;
  cropstage: string[];
  parentId: number;
  userId: number;
}
