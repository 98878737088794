import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';

/** Component which holds Menu functionality*/
@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  /** Constructor which holds services
   * @param {SnackBarComponent}
   * @param {MAT_SNACK_BAR_DATA}
   */
  /** Constructor which holds Methods and servises */
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data
  ) {}

  /** ngOninit lifecycle hook */
  ngOnInit(): void {
    // not in use
  }
}
