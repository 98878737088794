import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalValuesService } from '../global-values/global-values.service';

import { Subject } from 'rxjs';
import {
  getupdateReadonlyResponse,
  setFormchangeResponse
} from '../../models/serivces-model/template-service.model';
import { ContentDetailResponse } from '../../models/template/content/content.response';
import { MultiLingualResponse } from '../../models/template/multi-lingual/multilingual-params';

@Injectable({
  providedIn: 'root'
})

/** Service to get all Template details  */
export class TemplateService {
  /**
   * Creates an instance of TemplateService.
   *
   * @constructor
   * @param {HttpClient} http
   * @param {GlobalValuesService} globalValue
   * @param {SessionStorageService} sessionStorageService
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /** Variable which holds ListFaq API url */
  classDefinitionUrl: string =
    this.globalValue.creatorBaseURL + '/class/definition?className=';

  /** Function to handle getTemplate */
  getClassDefinition(className: string) {
    return this.http.get(this.classDefinitionUrl + className);
  }

  /** Variable which holds ListFaq API url */
  contentDetailUrl: string =
    this.globalValue.creatorBaseURL + 'content/view?id=';

  /** Function to handle getTemplate */
  getContentDetail(id: number): Observable<ContentDetailResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.get<ContentDetailResponse>(this.contentDetailUrl + id, {
      headers
    });
  }

  /** Variable which holds Content View API url */
  contentViewUrl: string = this.globalValue.creatorBaseURL + 'content/view?id=';

  /** Function to handle Content View */
  getContentView(id: number) {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData?.token
    });

    return this.http.get(this.contentViewUrl + id, { headers });
  }

  /** Variable which holds ListFaq API url */
  deleteContentUrl: string =
    this.globalValue.creatorBaseURL + 'content/delete?id=';

  /** Function to handle getTemplate */
  deleteContent(id: number) {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.delete(this.deleteContentUrl + id, { headers });
  }

  private updateReadonly = new Subject<getupdateReadonlyResponse>();

  /** Function to handle setMenuItemInfo */
  setupdateReadonly(val: getupdateReadonlyResponse): void {
    this.updateReadonly.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getupdateReadonly(): Observable<getupdateReadonlyResponse> {
    return this.updateReadonly.asObservable();
  }

  /** Variable which holds ListFaq API url */
  multiLingualUrl: string =
    this.globalValue.creatorBaseURL + 'content/languages';

  /** Function to handle get multilingual */
  getMultiLingual(params) {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.get<MultiLingualResponse>(this.multiLingualUrl, {
      params: params,
      headers: headers
    });
  }

  private formchange = new Subject<setFormchangeResponse>();

  /** Function to handle setMenuItemInfo */
  setFormchange(val: setFormchangeResponse): void {
    this.formchange.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getFormchange(): Observable<setFormchangeResponse> {
    return this.formchange.asObservable();
  }
}
