import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContentTrackerMasterList } from '../../models/services/content-tracker-models';

@Injectable({
  providedIn: 'root'
})
/** Interface which holds MasterListContentTrackerService */
export class MasterListContentTrackerService {
  baseUrl = environment.baseUrl;
  setPath = 'contenttracker/master/list';

  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   */
  constructor(private http: HttpClient) {}

  /** Function to handle getMasterList*/
  getMasterList(
    classname,
    search,
    start,
    pagesize
  ): Observable<ContentTrackerMasterList> {
    const url = this.baseUrl + this.setPath;

    return this.http.get<ContentTrackerMasterList>(url, {
      params: {
        className: classname,
        search: search,
        start: start,
        pageSize: pagesize
      }
    });
  }
}
