import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GlobalValuesService } from '../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})
/** Service to get all header details  */
export class HeaderService {
  showSideNav = false;
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private menuSubject = new Subject<any>(); /** * dynamic key and hierarchical involved on key value pair, due to which used any*/
  private sessionSubject = new Subject<boolean>();
  private timerSubject = new Subject<boolean>();
  private clearTimerSubject = new Subject<boolean>();
  private createContentClick = new Subject<boolean>();

  /**
   * Constructor which holds httpclient
   * @param http HTTPClient imports
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService
  ) {}

  /** Function to handle toggleSideNav */
  toggleSideNav(): void {
    this.showSideNav = !this.showSideNav;
  }

  /** Function to handle close SideNav */
  closeSideNav(): void {
    this.showSideNav = false;
  }


  /** Function to handle setMenuItemInfo */
  setMenuItemInfo(val): void {
    this.menuSubject.next(val);
  }

  /** Function to handle getMenuItemInfo 
   * dynamic key and hierarchical involved on key value pair, due to which used*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMenuItemInfo(): Observable<any> {
    return this.menuSubject.asObservable();
  }

  faqURL: string = this.globalValue.creatorBaseURL + 'get/constants';
  /** Function to get Constants*/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getConstants(): Observable<any> {
    return this.http.get(this.faqURL);
  }

  /** Function to handle setMenuItemInfo */
  setSessionInfo(val: boolean): void {
    this.sessionSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getSessionInfo(): Observable<boolean> {
    return this.sessionSubject.asObservable();
  }

  /** Function to handle setMenuItemInfo */
  setTimerInfo(val: boolean): void {
    this.timerSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getTimerInfo(): Observable<boolean> {
    return this.timerSubject.asObservable();
  }

  /** Function to handle setMenuItemInfo */
  setClearTimerInfo(val: boolean): void {
    this.clearTimerSubject.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getClearTimerInfo(): Observable<boolean> {
    return this.clearTimerSubject.asObservable();
  }
  
  /** Function to handle setMenuItemInfo */
  setCreateContentClick(val: boolean): void {
    this.createContentClick.next(val);
  }

  /** Function to handle getMenuItemInfo */
  getCreateContentClick(): Observable<boolean> {
    return this.createContentClick.asObservable();
  }
}
