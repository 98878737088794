export const environment = {
  production: false,
  baseUrl: 'https://uat.ccportal.agrowsmart.bayer.com/api/',
  clientID: 'e962f285-1d2f-4358-b990-28ee9f3fd0eb',
  authority:
    'https://login.microsoftonline.com/fcb2b37b-5da0-466b-9b83-0014b67a7c78',
  redirectURI:
    'https://uat.ccportal.agrowsmart.bayer.com/content-creator/panel/dashboard',
  // baseUrl :  config[process.env.CCPORTAL_ENV!.toLowerCase()].publicHost,
  // clientID:  config[process.env.CCPORTAL_ENV!.toLowerCase()].clientID,
  // redirectURI:config[process.env.CCPORTAL_ENV!.toLowerCase()].redirectURI,
  // authority:config[process.env.CCPORTAL_ENV!.toLowerCase()].authority
};
