/** class which holds ContentSaveResponse functionality */
export class curatorText {
  static copyright = 'Copyright';
  static bayer = 'Bayer AG';
  static bayerTag = 'Science for a better life';
  static logout = 'Logout';
  static support = 'Support';
  static success = 'Success';
  static error = 'Error';
  static welcome = 'Welcome';
  static utilSelection = 'Utility Selection';
  static utilQues = 'What type of utility would you like to select?';
  static utilType = 'Select Utility Type';
  static next = 'Next';
  static addFiles = 'Add Files Below';
  static upload = 'Upload';
  static process = 'Process';
  static summary = 'Summary';
  static view = 'View';
  static download = 'Download';
  static reUpload = 'Re-Upload';
  static delete = 'Delete';
  static fileName = 'File Name';
  static of = 'of';
  static actions = 'Actions';
  static contentType = 'Select Content Type';
  static attrName = 'Attribute Name';
  static type = 'Type';
  static add = 'Add';
  static save = 'Save';
  static back = 'Back';
  static edit = 'Edit';
  static contType = 'Content Type';
  static tempType = 'Template Type';
  static tempId = 'Template Id';
  static addURL = 'Add Website URL below';
  static launch = 'Launch';
  static locator = 'Locator';
  static webURL = 'Website URL';
  static noData = 'No Data Found';
  static title = 'Title';
  static titleAndUrl = 'Enter Title and URL';
  static editurl = 'Edit URL';
  static url = 'URL';
  static country = 'Country';
  static crop = 'Crop';
  static stage = 'Stage';
  static errorpopup = 'Error Popup';
  static notabletoparse = 'Not able to parse';
  static contenttype404 = 'Not matching content types:';
  static attrtype404 = 'Not matching attribute names:';
  static fixreupload = '**Please fix these errors and upload again for parsing';
  static value = 'value';
  static search = 'search';
  static deloutline = 'delete_outline';
  static LavanyaDS = 'Lavanya DS';
  static noattriavailable = 'No Attributes available';
  static direct = 'Direct';
  static close = 'close';
  static cropcontentType = 'Crop & Content Type';
  static benchmark = 'Benchmark';
  static addoredit = 'Add/Edit';
  static invalidStartDate = 'Invalid start date';
  static invalidEndDate = 'Invalid end date';
  static cancel = 'Cancel';
  static results404 = 'No Results Found';
  static creatorName = 'Creator Name';
}
