import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';

import { GlobalValuesService } from '../global-values/global-values.service';
import { DocParserRequest } from '../../models/utilities/docParser.request';
import { DocParserResponse } from '../../models/utilities/docParser.response';
import {
  WebScrapperResponse
} from '../../models/utilities/webscrapper.response';
import { WebScrapperRequest } from '../../models/utilities/webScrapper.request';

@Injectable({
  providedIn: 'root'
})

/** Service to get all dashboard details  */
export class UitiltyService {
  /** Creates an instance of documenter
   * @param {HttpClient}
   * @param {GlobalValuesService}
   * @param {SessionStorageService}
   */

  /**
   * Constructor which holds httpclient
   * @param http HTTPClient imports
   */
  constructor(
    public http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  /** Variable which holds internalLoginUrl */
  docParserUrl: string =
    this.globalValue.creatorBaseURL + 'get/contentbyoptions';

  /** Function to get all dashboard details
   * @return response that hold dashboard related values
   */
  getDocParserDetails(
    docParserParam: DocParserRequest
  ): Observable<DocParserResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.post<DocParserResponse>(
      this.docParserUrl,
      docParserParam,
      { headers }
    );
  }

  /** Variable which holds internalLoginUrl */
  webScrapperUrl: string = this.globalValue.creatorBaseURL + 'scrap/content';
  /** Function to get all dashboard details
   * @return response that hold dashboard related values
   */
  getWebScrapperDetails(
    webScrapperParam: WebScrapperRequest
  ): Observable<WebScrapperResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.post<WebScrapperResponse>(
      this.webScrapperUrl,
      webScrapperParam,
      { headers }
    );
  }

  /** Varaible which holds value of webScrapperSubject */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private webScrapperSubject = new Subject<any[]>();

  /** Function to handle setWebScrapperInfo */
  setWebScrapperInfo(val): void {
    this.webScrapperSubject.next(val);
  }

  /** Function to handle getWebScrapperInfo */
  getWebScrapperInfo() {
    return this.webScrapperSubject.asObservable();
  }
  webScrapper: string[] = [];

  /** Function to handle Get webScrapperData */
  get webScrapperData(): string[] {
    return this.webScrapper;
  }
  /** Function to handle Set webScrapperData */
  set webScrapperData(val: string[]) {
    this.webScrapper = val;
  }
}
