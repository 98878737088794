import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';
import { Observable, Subject } from 'rxjs';

import {
  MasterDetailRequestResponse,
  MasterListRequestResponse
} from '../../../models/template/many-to-one-relation/many-to-one-relation';
import { GlobalValuesService } from '../../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})

/** Service to get all ManyToOneRelation details  */
export class ManyToOneRelationService {
  /**
   * Constructor which holds httpclient and service
   * @param http HTTPClient imports
   * @param {GlobalValuesService}
   */
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  public mt1Subject = new Subject<FormGroup>();

  /** Function to handle setmt1ItemInfo */
  setmt1ItemInfo(val: FormGroup): void {
    this.mt1Subject.next(val);
  }

  /** Function to handle getmt1ItemInfo */
  getmt1ItemInfo(): Observable<FormGroup> {
    return this.mt1Subject.asObservable();
  }

  /** Variable which holds mt1SearchDataUrl */
  mt1SearchDataUrl: string = this.globalValue.creatorBaseURL + 'master/list';

  /**
   * Function to handle getManyToOneSearchData
   * @param params
   */
  getManyToOneSearchData(
    params,
    pimIdNotRequired?: boolean
  ): Observable<MasterListRequestResponse> {
    const userDetails = this.sessionStorageService.get('userDetails');
    if (
      userDetails.workflowConfigurations.length > 0 &&
      userDetails.workflowConfigurations[0][params.className] &&
      !pimIdNotRequired
    ) {
      const t =
        userDetails.workflowConfigurations[0][params.className].join('|');
      if (t !== '') {
        if (params.search === undefined)
          params.search = '{"AND":{},"OR":{"pim_id":"' + t + '"}}';
        else {
          const x = params.search.indexOf('OR');
          if (params.search[x + 5] !== '}') {
            params.search =
              params.search.slice(0, x + 5) +
              '"pim_id":"' +
              t +
              '",' +
              params.search.slice(x + 5);
          } else {
            params.search =
              params.search.slice(0, x + 5) +
              '"pim_id":"' +
              t +
              '"' +
              params.search.slice(x + 5);
          }
        }
      }
    }

    return this.http.get<MasterListRequestResponse>(this.mt1SearchDataUrl, {
      params: params
    });
  }

  getGlobalLanguage(params): Observable<MasterListRequestResponse> {
    return this.http.get<MasterListRequestResponse>(this.mt1SearchDataUrl, {
      params: params
    });
  }

  mt1SearchDataContentUrl: string =
    this.globalValue.creatorBaseURL + 'form/content/list';

  /**
   * Function to handle getManyToOneSearchData
   * @param params
   */
  getManyToOneSearchDataContent(params): Observable<MasterListRequestResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.get<MasterListRequestResponse>(
      this.mt1SearchDataContentUrl,
      {
        params: params,
        headers: headers
      }
    );
  }

  /** Variable which holds mt1 Search Detail Url */
  mt1SearchDetailUrl: string =
    this.globalValue.creatorBaseURL + 'master/detail?masterId=';

  /** function to get Many To One SearchDetail */
  getManyToOneSearchDetail(
    id: number
  ): Observable<MasterDetailRequestResponse> {
    return this.http.get<MasterDetailRequestResponse>(
      this.mt1SearchDetailUrl + id
    );
  }
}
