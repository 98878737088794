import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs/internal/Observable';
import {
  MtmContentTypeResponse
} from 'src/app/content-creator/models/template/many-to-many-relation/many-to-many-relation.model';
import { MasterListRequestResponse } from '../../../models/template/many-to-one-relation/many-to-one-relation';
import { GlobalValuesService } from '../../global-values/global-values.service';

@Injectable({
  providedIn: 'root'
})
export class ManyToManyRelationService {
  constructor(
    private http: HttpClient,
    public globalValue: GlobalValuesService,
    public sessionStorageService: SessionStorageService
  ) {}

  mt1SearchDataUrl: string = this.globalValue.creatorBaseURL + 'master/list';

  /** Function to handle getSearchData */
  getManyToManySearchData(params): Observable<MasterListRequestResponse> {
    return this.http.get<MasterListRequestResponse>(this.mt1SearchDataUrl, {
      params: params
    });
  }

  mt1SearchDataContentTypeUrl: string =
    this.globalValue.creatorBaseURL + 'form/content/list';

  /** Function to handle getSearchData */
  getManyToManySearchDataContentType(
    params
  ): Observable<MtmContentTypeResponse> {
    const sessionData = this.sessionStorageService.get('userToken');
    const headers = new HttpHeaders({
      token: sessionData.token
    });

    return this.http.get<MtmContentTypeResponse>(
      this.mt1SearchDataContentTypeUrl,
      {
        params: params,
        headers: headers
      }
    );
  }

  mtmSearchDetailUrl: string = this.globalValue.creatorBaseURL + 'master/list';

  /** Function to handle getSearchData */
  getManyToManySearchDetail(
    params
  ): Observable<MasterListRequestResponse> {
    return this.http.get<MasterListRequestResponse>(this.mtmSearchDetailUrl, {
      params: params
    });
  }
}
