/** Class which holds DocParserRequest */
export class DocParserRequest {}

/** Interface which holds DocParserRequest */
export interface DocParserRequest {
  templateTypes: string[];
  country: string;
  crop: string;
  cropstage: string[];
  contentType: string[];
}
