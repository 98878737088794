<!--Advanced Search Results Shimmer-->
<ng-container
  class="col-9 pl-5 advanced-search-results"
  *ngIf="shimmerType == 'results'"
>
  <div class="results" *ngFor="let block of tenBlocks">
    <div class="row result mx-0 shimmer">
      <div class="col-1 pr-0 pl-0 icon">
        <mat-icon class="shine"></mat-icon>
      </div>

      <div class="col-10 pl-0">
        <div class="small-lines shine"></div>

        <br />

        <div class="lines shine"></div>
      </div>

      <div class="col-1 pr-0 icon">
        <mat-icon class="shine float-right"></mat-icon>
      </div>
    </div>
  </div>
</ng-container>

<!--Dashboard Status Panel Shimmer-->
<ng-container *ngIf="shimmerType == 'panel'">
  <div class="row mt-2">
    <div class="col-2" *ngFor="let item of sixBlocks">
      <div class="metrics-cont shimmer">
        <div class="metrics-count float-right shine"></div>

        <div class="metrics-text shine"></div>
      </div>
    </div>
  </div>
</ng-container>

<!--Dashboard Table Shimmer-->
<ng-container *ngIf="shimmerType == 'table'">
  <table class="w-100 table shimmer">
    <tr>
      <th *ngFor="let row of sixBlocks">
        <div class="line shine"></div>
      </th>
    </tr>

    <ng-container *ngFor="let iter of [1, 2, 3]">
      <tr *ngFor="let row of sixBlocks">
        <td *ngFor="let row of sixBlocks">
          <div class="line shine"></div>
        </td>
      </tr>

      <tr></tr>
    </ng-container>
  </table>
</ng-container>

<!--Template Creation Form Shimmer-->
<ng-container *ngIf="shimmerType == 'creation-form'">
  <div class="row">
    <div class="container px-0 form-cont shimmer">
      <!--Fill Form-->
      <div class="row mx-0">
        <div class="col-9">
          <div class="fill-form shine"></div>
          <br />
        </div>

        <div class="col-3">
          <div class="row float-right mx-0">
            <div class="px-2 icon">
              <mat-icon class="shine"></mat-icon>
            </div>

            <div class="px-2 icon">
              <mat-icon class="shine"></mat-icon>
            </div>

            <div class="px-2 icon">
              <mat-icon class="shine"></mat-icon>
            </div>
          </div>
        </div>
      </div>

      <!--Title block-->
      <div class="row mx-3 mb-4 grey-box">
        <div class="col-8">
          <div class="small-lines shine d-block m-3"></div>

          <div class="lines shine d-block m-3"></div>
        </div>

        <div class="d-block col-4 float-right">
          <div class="small-lines shine d-block m-3"></div>

          <div class="lines shine d-block m-3"></div>
        </div>
      </div>

      <!--Basic infor tab-->
      <div class="row mx-3">
        <div class="tab shine mr-2"></div>

        <div class="tab shine mx-2"></div>

        <div class="tab-small shine mx-2"></div>

        <div class="tab-small shine mx-2"></div>
      </div>

      <hr class="mb-3" />

      <div class="row mx-3 mb-4 grey-box">
        <div class="col-6 p-3">
          <div class="my-2 mx-3" *ngFor="let i of [1, 2, 3, 4]">
            <div class="tab-small shine"></div>

            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>

        <div class="col-6 p-3">
          <div class="mt-2 mb-5 mx-3" *ngFor="let i of [1, 2]">
            <div class="tab-small shine"></div>

            <br />

            <div class="dropdown shine"></div>
          </div>

          <div class="button mx-3 shine"></div>
        </div>
      </div>

      <!--Next dropdown block-->
      <div class="row mx-3 mb-4 grey-box">
        <div class="col-6 p-3">
          <div class="my-2 mx-3" *ngFor="let i of [1, 2]">
            <div class="tab-small shine"></div>

            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>

        <div class="col-6 p-3">
          <div class="my-2 mx-3" *ngFor="let i of [1, 2]">
            <div class="tab-small shine"></div>
            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>
      </div>

      <!--Crop specific fields-->
      <div class="row mx-3">
        <div class="tab shine mx-2"></div>
      </div>

      <div class="row mx-3 mt-3 mb-4 grey-box">
        <div class="col-6 p-3">
          <div class="my-2 mx-3" *ngFor="let i of [1, 2]">
            <div class="tab-small shine"></div>
            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>

        <div class="col-6 p-3">
          <div class="my-2 mx-3">
            <div class="tab-small shine"></div>
            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>
      </div>

      <!--Location Mappings-->
      <div class="row mx-3 mb-4 grey-box">
        <div class="col-6 p-3">
          <div class="my-2 mx-3" *ngFor="let i of [1, 2]">
            <div class="tab-small shine"></div>
            <br />

            <div class="dropdown shine"></div>
          </div>
        </div>

        <div class="col-6 p-3">
          <div class="my-2 mb-5 mx-3">
            <div class="tab-small shine"></div>
            <br />

            <div class="dropdown shine"></div>
          </div>

          <div class="button mx-3 shine"></div>
        </div>

        <hr class="hr w-100 mx-3" />

        <div class="tab-small mx-3 mb-3 shine"></div>
        <br />

        <table class="w-100 mx-3 table shimmer">
          <tr class="table-heading">
            <th *ngFor="let row of [1, 2, 3, 4]">
              <div class="line shine"></div>
            </th>
          </tr>

          <tr *ngFor="let row of [1, 2, 3]">
            <td *ngFor="let row of [1, 2, 3, 4]">
              <div class="line shine"></div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-container>

<!--Multimedia tree-structure screen shimmer-->
<ng-container *ngIf="shimmerType == 'treeStructure'">
  <div class="shimmer tree-structure">
    <div class="folder-box">
      <div class="shine folder-text"></div>
    </div>

    <div class="d-flex folder-data" *ngFor="let tree of treeData">
      <div class="shine plus-icon"></div>

      <div class="shine tree-name"></div>
    </div>
  </div>
</ng-container>

<!--Multimedia Image Gallary screen shimmer-->
<ng-container *ngIf="shimmerType == 'showImage'">
  <div class="mt-0 mr-0 row icons shimmer float-right">
    <div class="icon-1">
      <div class="search shine mx-1"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine mx-1"></div>
    </div>

    <div class="icon-3">
      <div class="upload shine mx-1"></div>
    </div>

    <div class="icon-4">
      <div class="separator mx-1 shine"></div>
    </div>

    <div class="icon-5">
      <div class="download mx-1 shine"></div>
    </div>

    <div class="icon-6">
      <div class="separator mx-1 shine"></div>
    </div>

    <div class="icon-7">
      <div class="edit-image mx-1 shine"></div>
    </div>

    <div class="icon-8">
      <div class="separator mx-1 shine"></div>
    </div>

    <div class="icon-9">
      <div class="delete mx-1 shine"></div>
    </div>
  </div>

  <div class="img-gallery row">
    <div class="gallery-box col-3 shimmer" *ngFor="let image of images">
      <div class="img-label-boxes">
        <div class="img shine"></div>

        <div class="medium-line shine"></div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Multimedia Edit screen shimmer-->
<ng-container *ngIf="shimmerType == 'editImage'">
  <div class="row ml-2 mt-1">
    <div class="edit col-6 shimmer">
      <div class="info shine mb-2"></div>

      <div class="d-flex">
        <div class="img2 shine"></div>

        <div class="crop-icon shine align-self-end ml-2"></div>
      </div>

      <div class="d-block mt-2">
        <mat-icon class="check-box mr-2 shine"></mat-icon>

        <div class="big-line shine"></div>
      </div>

      <div class="d-block mt-1">
        <mat-icon class="check-box mr-2 shine"></mat-icon>

        <div class="big-line shine"></div>
      </div>
    </div>
    <div class="edit2 shimmer col-6">
      <div class="info2 shine mb-2"></div>

      <div class="img3 shine"></div>
    </div>
  </div>
  <div class="img-process shimmer d-flex">
    <div class="process-box shine mr-3"></div>

    <div class="add-box shine mr-3"></div>

    <div class="back-box shine mt-2"></div>
  </div>
</ng-container>

<!-- Multimedia Edit screen resize shimmer-->
<ng-container *ngIf="shimmerType == 'resize'">
  <div class="pl-3 resize-box shimmer">
    <div class="d-block">
      <div class="radio mr-2 shine"></div>

      <div class="size shine"></div>
    </div>

    <div class="d-block shimmer">
      <div class="radio mr-2 shine"></div>

      <div class="resize shine"></div>
    </div>
  </div>
</ng-container>

<!-- Multimedia Edit screen setsize shimmer -->
<ng-container *ngIf="shimmerType == 'setsize'">
  <div class="setsize-box pl-3 shimmer">
    <div class="d-block">
      <div class="width shine">
        <div class="width-value ml-3 shine"></div>
      </div>
    </div>

    <div class="d-block">
      <div class="width shine mr-2">
        <div class="width-value ml-3 shine"></div>
      </div>
    </div>
  </div>
</ng-container>

<!--Multimedia Preview-image Shimmer-->
<ng-container *ngIf="shimmerType == 'prevImage'">
  <div class="row icons shimmer float-right icon-box">
    <div class="icon-1">
      <div class="search shine mx-1"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine mx-1"></div>
    </div>

    <div class="icon-3">
      <div class="upload shine mx-1"></div>
    </div>

    <div class="icon-4">
      <div class="separator mx-1 shine"></div>
    </div>

    <div class="icon-5">
      <div class="download mx-1 shine"></div>
    </div>
  </div>

  <div class="row pt-3">
    <div class="image col-md-8 col-sm-8 col-lg-8 shimmer">
      <div class="multimedia-image shine"></div>
    </div>

    <div class="details col-md-4 col-sm-4 col-lg-4 shimmer">
      <div class="info-text shine"></div>

      <table class="multimedia-info-table">
        <div *ngFor="let iter of [1, 2, 3]">
          <tr>
            <td class="table-data" *ngFor="let row of twoBlocks">
              <div class="data shine"></div>
            </td>
          </tr>

          <label class="hr-line shine"></label>
        </div>
      </table>
    </div>

    <div class="back-btn shine ml-5"></div>
  </div>
</ng-container>

<!-- Content-creation Shimmer -->
<ng-container class="container" *ngIf="shimmerType == 'content-creation'">
  <div class="content-creation">
    <div class="nav">
      <div class="breadcrumb shimmer">
        <li class="breadcrumb-item shine"></li>

        <mat-icon class="arrow shine"></mat-icon>

        <li class="breadcrumb-item shine"></li>
      </div>
    </div>

    <div class="row mx-0 progressbar shimmer">
      <span class="col-2 progress shine"></span>

      <span class="col-2 progress shine"></span>

      <span class="col-2 progress shine"></span>
    </div>

    <div class="question-line shine"></div>

    <div class="row select-dropdown shimmer">
      <div class="col-6">
        <div class="select-type shine"></div>

        <div class="dropdown mt-2">
          <div class="dropdown-1 shine"></div>
        </div>
      </div>

      <div class="col-6">
        <div class="select-type shine"></div>

        <div class="dropdown mt-2">
          <div class="dropdown-1 shine"></div>
        </div>
      </div>
    </div>
    <div class="row next-cancel mx-0 shimmer">
      <div class="next-button shine"></div>

      <div class="cancel-button shine"></div>
    </div>
  </div>
</ng-container>

<!-- Searchbox shimmer  -->
<ng-container *ngIf="shimmerType == 'search-box'">
  <table class="w-100 searchbox-info-table shimmer">
    <div class="bg-table">
      <ng-container *ngFor="let iter of [1]">
        <tr>
          <td class="search shine" *ngFor="let row of fourBlocks">
            <div class="searchdata shine"></div>
          </td>
        </tr>
      </ng-container>
    </div>

    <ng-container *ngFor="let iter of [1, 2, 3, 4, 5]">
      <tr>
        <td class="search shine" *ngFor="let row of fourBlocks">
          <div class="searchdata shine"></div>
        </td>
      </tr>
      <label class="hr-line shine"></label>
    </ng-container>
  </table>
</ng-container>

<!-- Search-results list shimmer -->
<ng-container *ngIf="shimmerType == 'search-result'">
  <div class="results shimmer">
    <div *ngFor="let item of fiveBlocks">
      <div class="row search-data">
        <div class="col-10 title-category">
          <div class="title shine"></div>

          <div class="category shine"></div>
        </div>

        <div class="col-2 new-tab">
          <div class="tab-icon shine"></div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="col advance shine"></div>
</ng-container>

<!-- dynamic-attributes list shimmer -->
<ng-container *ngIf="shimmerType == 'dynamic-attributes'">
  <table class="w-100 dynamic-attributes-table shimmer">
    <div class="ds-table">
      <ng-container *ngFor="let iter of [1]">
        <tr>
          <td class="shine" *ngFor="let row of fiveBlocks">
            <div class="shine"></div>
          </td>
        </tr>
      </ng-container>
    </div>

    <ng-container *ngFor="let iter of [1, 2, 3, 4, 5]">
      <tr>
        <td class="shine" *ngFor="let row of fiveBlocks">
          <div class="shine"></div>
        </td>
      </tr>

      <label class="hr-line shine"></label>
    </ng-container>
  </table>
</ng-container>

<!-- Timeline Chart Shimmer -->
<ng-container *ngIf="shimmerType == 'timeline'">
  <div class="container timeline shimmer mt-4">
    <div class="container row justify-content-between">
      <div class="col-auto toggle-boxes shine"></div>

      <div class="col-auto filter shine ml-5"></div>
    </div>
    <div class="container bg-clr">
      <div class="row justify-content-between">
        <div class="col-auto timeline-bar shine"></div>

        <div class="d-flex justify-content-end fields">
          <div class="col-auto field-box shine"></div>

          <div class="col-auto field-box shine"></div>

          <div class="col-auto field-box shine"></div>
        </div>
      </div>
      <div class="row d-flex flex-row-reverse">
        <div class="p-4 d-flex align-items-center">
          <div class="mr-1 square-box shine"></div>

          <div class="status shine"></div>
        </div>

        <div class="p-4 d-flex align-items-center">
          <div class="mr-1 square-box shine"></div>

          <div class="status shine"></div>
        </div>

        <div class="p-4 d-flex align-items-center">
          <div class="mr-1 square-box shine"></div>

          <div class="status shine"></div>
        </div>

        <div class="p-4 d-flex align-items-center">
          <div class="mr-1 square-box shine"></div>

          <div class="status shine"></div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="chart shine"></div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Initiate Translation shimmer  -->
<ng-container *ngIf="shimmerType == 'translation'">
  <table class="w-100 translationbox-info-table shimmer">
    <div class="bg-table">
      <ng-container *ngFor="let iter of [1]">
        <tr>
          <td class="search shine" *ngFor="let row of twoBlocks">
            <div class="searchdata shine"></div>
          </td>
        </tr>
      </ng-container>
    </div>

    <ng-container *ngFor="let iter of [1, 2, 3, 4]">
      <tr>
        <td class="search shine" *ngFor="let row of twoBlocks">
          <div class="searchdata shine"></div>
        </td>
      </tr>

      <label class="hr-line shine"></label>
    </ng-container>
  </table>
</ng-container>

<!-- Dropdown Shimmer -->
<ng-container *ngIf="shimmerType == 'dropdown'">
  <div class="dropdown-options shimmer">
    <div *ngFor="let row of fiveBlocks">
      <div class="shine options"></div>
    </div>
  </div>
</ng-container>

<!--Dashboard & Translation Summary buttons shimmer-->
<ng-container *ngIf="shimmerType == 'toggle-buttons'">
  <div class="container">
    <div class="container toggle-btns shine"></div>
  </div>
</ng-container>

<!--Dashboard icons shimmer-->
<ng-container *ngIf="shimmerType == 'dashboard-icons'">
  <div class="dashboard-icons shimmer col-auto d-flex align-items-center">
    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>

    <div class="icon-2">
      <div class="separator shine"></div>
    </div>

    <div class="icon-1">
      <div class="search shine"></div>
    </div>
  </div>
</ng-container>

<!--Dashboard & Translation Summary buttons shimmer-->
<ng-container *ngIf="shimmerType == 'translation-popup-inProgress'">
  <div class="container">
    <div class="container dropdown-progress shine"></div>
  </div>
</ng-container>

<!--Dashboard metrics Table Shimmer-->
<ng-container class="mt-1" *ngIf="shimmerType == 'metricTable'">
  <div class="mt-2 mb-2">
    <div class="line shine"></div>
  </div>

  <table class="w-100 table shimmer">
    <tr>
      <th *ngFor="let row of fourBlocks">
        <div class="line shine"></div>
      </th>
    </tr>

    <ng-container *ngFor="let iter of [1]">
      <tr *ngFor="let row of fiveBlocks">
        <td *ngFor="let row of fourBlocks">
          <div class="line shine"></div>
        </td>
      </tr>
      <tr></tr>
    </ng-container>
  </table>
</ng-container>

<!--Page Insight-->
<ng-container *ngIf="shimmerType == 'pageInsight'">
  <div class="container d-flex col-12 justify-content-between p-0 mb-4">
    <div class="col-6 pl-0">
      <div class="d-flex justify-content-between mb-4">
        <div class="line shine metricsSize"></div>

        <div class="line shine metricsSize"></div>
      </div>
      <div class="line shine doughtnutSize"></div>
    </div>

    <div class="col-6 pr-0">
      <div class="d-flex topmargin flex-column justify-content-between">
        <div class="d-flex justify-content-between mb-4">
          <div class="line shine mr-3 metricsSize"></div>

          <div class="line shine metricsSize"></div>
        </div>

        <div class="line shine doughtnutSize"></div>
      </div>
    </div>
  </div>

  <div class="mt-5 container">
    <div class="d-flex justify-content-start">
      <div class="line shine mb-4 tableHeading"></div>
    </div>
    <hr />

    <div class="mt-2 mb-2">
      <div class="line shine"></div>
    </div>

    <table class="w-100 table shimmer">
      <tr>
        <th *ngFor="let row of fourBlocks">
          <div class="line shine"></div>
        </th>
      </tr>

      <ng-container *ngFor="let iter of [1]">
        <tr *ngFor="let row of sixBlocks">
          <td *ngFor="let row of fourBlocks">
            <div class="line shine"></div>
          </td>
        </tr>
        <tr></tr>
      </ng-container>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="shimmerType == 'doughnut'">
  <div class="card shadow">
    <div class="card-body p-1 pb-2 doughtnutRelative">
      <div class="row">
        <div class="col ml-3 mt-3">
          <div class="line shine subHeading"></div>

          <div class="doughnutDataPosition">
            <div class="d-flex mt-2">
              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine doughtnutCount"></div>
            </div>

            <div class="d-flex mt-2">
              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine doughtnutCount"></div>
            </div>

            <div class="d-flex mt-2">
              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine mr-4 doughtnutKeyValue"></div>

              <div class="line shine doughtnutCount"></div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="mt-3 chartClass line shine"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Dashboard metrics panel Shimmer-->
<ng-container *ngIf="shimmerType == 'metricPanel'">
  <div class="line shine panelSize"></div>
</ng-container>

<!--Dashboard metrics bar chart-->
<ng-container *ngIf="shimmerType == 'barChart'">
  <div class="mb-5 mt-5 lines shine barChartSize"></div>
</ng-container>

<!--Dashboard metrics horizontal bar chart-->
<ng-container *ngIf="shimmerType == 'horizontalBarChart'">
  <div class="lines shine horizontalBarChartSize"></div>
</ng-container>

<!--Dashboard metrics Global Translation Table Shimmer-->
<ng-container class="mt-1" *ngIf="shimmerType == 'globalTranslationTable'">
  <div class="mt-2 mb-2">
    <div class="line shine"></div>
  </div>

  <table class="w-100 mb-4 table shimmer">
    <tr>
      <th *ngFor="let row of fourBlocks">
        <div class="line shine"></div>
      </th>
    </tr>

    <ng-container *ngFor="let iter of [1]">
      <tr *ngFor="let row of [1,2,3,4,5,6,7,8,9]">
        <td *ngFor="let row of fourBlocks">
          <div class="line shine"></div>
        </td>
      </tr>
      <tr></tr>
    </ng-container>
  </table>
</ng-container>