import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalValuesService {
  creatorBaseURL = '';
  httpOptions = [];

  /** Constructor lifecycle hook */
  constructor() {
    this.creatorBaseURL = environment.baseUrl;
  }
}
