import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from 'angular-web-storage';
import { Subscription } from 'rxjs';

import { DialogBoxComponent } from './content-creator/components/common/dialog-box/dialog-box.component';
import { HeaderService } from './content-creator/services/header/header.service';

/** Component which holds Component */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
/** Class which holds AppComponent title */
export class AppComponent {
  appSubscription = new Subscription();

  title = 'contentCreatorPortal';
  isIframe = false;
  id;
  logoutWarningShown = false;
  difference;

  constructor(
    public sessionStorageService: SessionStorageService,
    public dialog: MatDialog,
    public headerService: HeaderService
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.appSubscription.add(
      this.headerService.getSessionInfo().subscribe((value: boolean) => {
        this.logoutWarningShown = value;
        this.id = setInterval(() => {
          const token = this.sessionStorageService.get('bearerToken');
          const userToken = this.sessionStorageService.get('userToken');
          if (userToken && token && !this.logoutWarningShown) {
            if (this.tokenExpiredAlert(token.access_token, token.expires_in)) {
              const value = {
                message:
                  'Session is going to be expired in ' +
                  (this.difference / 60).toFixed() +
                  ' mins. Please extend your session or unsaved changes will be lost!',
                success: false
              };
              this.openDialogBox(value);
            }
          }
        }, 60000);
      })
    );
    this.appSubscription.add(
      this.headerService.getClearTimerInfo().subscribe((value: boolean) => {
        if (value) {
          this.logoutWarningShown = true;
          if (this.id) {
            clearInterval(this.id);
          }
        }
      })
    );
    this.headerService.setSessionInfo(false);
  }

  ngOnDestroy(): void {
    this.appSubscription.unsubscribe();
  }

  private tokenExpiredAlert(token: string, expiryTime: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    const currentTime = Math.floor(new Date().getTime() / 1000);
    this.difference = expiry - currentTime;
    this.sessionStorageService.set('expiryTime', expiry);
    const percentageOfCurrentTime =
      (this.difference / parseInt(expiryTime)) * 100;

    return parseInt(percentageOfCurrentTime.toFixed()) <= 16.667;
  }

  openDialogBox(value) {
    if (value) {
      this.headerService.setSessionInfo(true);
      const dialogRef = this.dialog.open(DialogBoxComponent, {
        data: { type: 'logout', value: value },
        height: 'auto',
        width: '800px',
        disableClose: true
      });
      this.appSubscription.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result.type === 'logout') {
            if (result.data === 'extended') {
              this.headerService.setSessionInfo(false);
            }
            if (result.data === 'cancel extended') {
              this.headerService.setClearTimerInfo(true);
            }
          }
        })
      );
    }
  }
}
