export class creatorText {
  static bayerTag = 'Science for a better life';
  static logoutText = 'Logout';
  static supportText = 'Support';
  static searchResultsText = 'Search Results';
  static filtersText = 'Filters';
  static creationDateText = 'Creation Date';
  static searchTextButton = 'Search';
  static resetText = 'Reset';
  static noResultFoundText = 'No Data found';
  static roleSwitcherText = 'Role Switcher';
  static roleSelection = 'Select from the roles listed below:';
  static proceedText = 'Proceed';
  static cancelText = 'Cancel';
  static deleteText = 'Delete';
  static deleteFormText = 'Delete form';
  static deleteConfirmation = 'Do you want to delete the form?';
  static successText = 'Success';
  static successMessage = 'Form has been submitted successfully!';
  static validationFailedText = 'Validation Failed';
  static errorMessage = 'Please fill the dependent field(s) first.';
  static createContentText = 'Create Content';
  static openNewTabText = 'Open in new tab';
  static bayerAgText = 'Bayer AG';
  static menuText = 'menu';
  static varaintsText = 'varaint';
  static splitScreenText = 'Splitscreen for Multilingual';
  static switchRoleText = 'Switch Role';
  static englishText = 'English';
  static item2Text = 'Item 2';
  static contentTypeText = 'Content Type';
  static advancedText = 'Advanced';
  static openText = 'Open';
  static copyText = 'Copy';
  static pasteText = 'Paste';
  static renameText = 'Rename';
  static refreshText = 'Refresh';
  static dashboardText = 'Dashboard';
  static contentSelection =
    'What type and category of content would you like to select?';
  static selectContentTypeText = 'Select Content Type';
  static PleaseSelectContentTypeText = 'Please select content type!';
  static selectContentCategoryText = 'Select Content Category';
  static PleaseSelectContentCategoryText = 'Please select content category!';
  static nextText = 'NEXT';
  static welcomeText = 'Welcome';
  static dashboardSummaryText = 'Dashboard Summary';
  static translationSummaryText = 'Translation Summary';
  static filterText = 'Filter';
  static applyText = 'Apply';
  static gridOptionsText = 'Grid Options';
  static selectgridColumnsText = 'Select grid columns';
  static deleteAllText = 'Delete All';
  static exportText = 'Export';
  static formFillup = 'Fill the form and add media!';
  static titleText = 'Title';
  static categoryHeading = 'In_En_Ri_Kh_Blast';
  static inProgressText = 'In Progress';
  static saveText = 'Save';
  static foldersText = 'Folders';
  static sampleImageText = 'Sample Image';
  static informationText = 'Information';
  static adjustText = 'Adjust brightness and contrast';
  static resizeText = 'Resize';
  static defaultSize = 'Default (500 x 500)';
  static setSizeText = 'Set size';
  static widthText = 'W';
  static heightText = 'H';
  static imagePreviewText = 'Image Preview';
  static processText = 'PROCESS';
  static addText = 'ADD';
  static backText = 'Back';
  static loginText = 'Login';
  static multimediaText = 'Multimedia';
  static agrowText = 'Agrow';
  static smartText = 'Smart';
  static agrowCaption = 'One Stop Agro Solutions';
  static userNameValidation = 'Username is incorrect';
  static userNameTouched = 'Username is required!';
  static passwordValidation = 'Password is required';
  static passwordCharacterCheck = 'Password is incorrect';
  static externalLoginButton = 'LOGIN';
  static internalLoginButton = 'LOGIN WITH CWID';
  static copyRightText = 'Copyright';
  static bayerContentText = 'Bayer AG. All rights reserved.';
  static availableText = 'Available:';
  static addBySearchText = 'ADD BY SEARCH';
  static generateKeywordsText = 'GENERATE KEYWORDS';
  static extendSessionText = 'Extend Session';
  static dashboardDetails = [
    {
      role: 'Dashboard-Creator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title: 'Content Name' },
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'WorkflowStatus', title: 'Status' },
          { name: 'Crop_MT1_Value', title: 'Crop Name' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'expiryDate', title: 'Review Date' },
          { name: 'DaysWithCreator', title: 'Days with Creator' },
          { name: 'DaysWithReviewer', title: 'Days with Content Reviewer' },
          {
            name: 'DaysWithStatutoryReviewer',
            title: 'Days with Statutory Reviewer'
          },
          { name: 'DaysWithApprover', title: 'Days with Approver' },
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'state', title: 'State' },
          { name: 'id', title: 'Content Id' }
        ],
        grid: {
          className: true,
          ContentType: true,
          key: true,
          creationDate: true,
          WorkflowStatus: true,
          Crop_MT1_Value: true,
          expiryDate: false,
          CreatorName: false,
          TotalDays: false,
          DaysWithCreator: false,
          DaysWithReviewer: false,
          DaysWithStatutoryReviewer: false,
          DaysWithApprover: false,
          Country_MT1_Value: false,
          state: false,
          id: false
        },
        filter: {
          contentType: '',
          contentCategory: '',
          Crop_MT1: '',
          Country_MT1: '',
          state: ''
        },
        statusCount: [
          { status: 'In Progress', value: 0, active: false },
          { status: 'Revisit Content', value: 0, active: false },
          { status: 'Content Review', value: 0, active: false },
          { status: 'Statutory Review', value: 0, active: false },
          { status: 'Approval', value: 0, active: false },
          { status: 'Published', value: 0, active: false }
        ]
      }
    },
    {
      role: 'Translation-Creator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title: 'Content Name' },
          { name: 'TranslationLanguage', title: 'Language' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'expiryDate', title: 'Review Date' },
          { name: 'TranslationStatus', title: 'Translation Status' },
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'wordCount', title: 'Word Count' },
          { name: 'pageCount', title: 'Page Count' },
          { name: 'UniversityExpertName', title: 'University Expert Name' },
          {
            name: 'TranslationCompletionDate',
            title: 'Translation Completion Date'
          }
        ],
        grid: {
          className: true,
          contentCategory: true,
          key: true,
          TotalDays: true,
          ContentType: true,
          creationDate: true,
          expiryDate: true,
          TranslationLanguage: true,
          TranslationStatus: true,
          Country_MT1_Value: false,
          wordCount: true,
          pageCount: true,
          UniversityExpertName: true,
          TranslationCompletionDate: true
        },
        filter: {
          contentType: '',
          contentCategory: '',
          Crop_MT1: '',
          Country_MT1: '',
          state: ''
        },
        statusCount: [
          { status: 'Translation Ready', value: 0, active: false },
          { status: 'In Translation', value: 0, active: false },
          { status: 'Translation Comp', value: 0, active: false }
        ]
      }
    },
    {
      role: 'Translator',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'key', title: 'Content Name' },
          { name: 'Crop_MT1_Value', title: 'Crop' },
          { name: 'id', title: 'Content Id' },
          { name: 'TranslationLanguage', title: 'Language' },
          { name: 'TranslationStatus', title: 'Status' },
          { name: 'wordCount', title: 'Word Count' },
          { name: 'pageCount', title: 'Page Count' },
          {
            name: 'TranslationCompletionDate',
            title: 'Translation Completion Date'
          }
        ],
        grid: {
          TranslationStatus: true,
          className: true,
          id: true,
          TranslationLanguage: true,
          receivedDate: true,
          Crop_MT1_Value: false,
          key: true,
          wordCount: true,
          pageCount: true,
          TranslationCompletionDate: true
        },
        filter: {
          contentType: '',
          contentCategory: '',
          cropName: '',
          country: '',
          state: ''
        },
        statusCount: [
          { status: 'Review in Progress', value: 0, active: false },
          { status: 'Revisit Content', value: 0, active: false },
          { status: 'In Translation', value: 0, active: false },
          { status: 'Completed', value: 0, active: false }
        ]
      }
    },
    {
      role: 'Content Reviewer',
      data: {
        column: [
          { name: 'className', title: 'Content Category' },
          { name: 'ContentType', title: 'Content Type' },
          { name: 'key', title: 'Content Name' },
          { name: 'Crop_MT1_Value', title: 'Crop Name' },
          { name: 'TotalDays', title: 'Total no. of days' },
          { name: 'creationDate', title: 'Creation Date' },
          { name: 'expiryDate', title: 'Review Date' },
          { name: 'CreatorName', title: 'Name' },
          { name: 'WorkflowStatus', title: 'Status' },
          { name: 'DaysWithCreator', title: 'Days with Creator' },
          { name: 'DaysWithReviewer', title: 'Days with Content Reviewer' },
          {
            name: 'DaysWithStatutoryReviewer',
            title: 'Days with Statutory Reviewer'
          },
          { name: 'DaysWithApprover', title: 'Days with Approver' },
          { name: 'Country_MT1_Value', title: 'Country' },
          { name: 'state', title: 'State' }
        ],
        grid: {
          WorkflowStatus: true,
          className: true,
          ContentType: true,
          key: true,
          Crop_MT1_Value: false,
          creationDate: true,
          expiryDate: true,
          CreatorName: true,
          TotalDays: true,
          DaysWithCreator: false,
          DaysWithReviewer: false,
          DaysWithStatutoryReviewer: false,
          DaysWithApprover: false,
          Country_MT1_Value: true,
          state: true
        },
        filter: {
          contentType: '',
          contentCategory: '',
          cropName: '',
          country: '',
          state: ''
        },
        statusCount: [
          { status: 'New Content', value: 0, active: false },
          { status: 'Revist Content', value: 0, active: false },
          { status: 'Content Review', value: 0, active: false },
          { status: 'Statutory Review', value: 0, active: false },
          { status: 'Approval', value: 0, active: false },
          { status: 'Published', value: 0, active: false }
        ]
      }
    }
  ];

  static recycleBinDetails = [
    {
      data: {
        column: [
          { name: 'className', title: 'Content Name' },
          { name: 'key', title: 'Key' },
          { name: 'id', title: 'File Id' },
          { name: 'path', title: 'Path' },
          { name: 'creationDate', title: 'Created Date' },
          { name: 'modificationDate', title: 'Deleted Date' }
        ],
        grid: {
          id: true,
          creationDate: true,
          modificationDate: true,
          path: true,
          key: true,
          className: true
        },
        filter: {
          id: '',
          contentCategory: '',
          path: '',
          creationDate: '',
          modificationDate: '',
          key: ''
        },
        statusCount: [
          { status: 'Review in Progress', value: 0, active: false },
          { status: 'Revisted Content', value: 0, active: false },
          { status: 'Content Review', value: 0, active: false },
          { status: 'Statutory Review', value: 0, active: false },
          { status: 'Approval', value: 0, active: false },
          { status: 'Published', value: 0, active: false }
        ]
      }
    }
  ];
  static categoryText = 'Category';
  static IWDText = 'IWD';
  static agronomyText = 'Agronomy';
  static prdouctsText = 'Prdoucts';
  static countryText = 'Country';
  static IndiaText = 'India';
  static PakistanText = 'Pakistan';
  static languageText = 'Language';
  static advancedSearchOpenText = 'Click here for advance search';
  static statusText = 'Status';
  static contentCategoryText = 'Content Category';
  static stateText = 'State';
  static cropText = 'Crop';
  static stageText = 'Stages';
  static documentPraserText = 'Document Praser';
  static spellCheckerText = 'Spell Checker';
  static webScrapperText = 'Web Scrapper';
  static keywordToolTipText =
    'Please seprate the Keywords using commas and Save the form before using Genrated Keywords.';
  static initiateTranslationText = 'Initiate Translation';
  static translationLanguages = 'Translation Languages';
  static multimediaDefaultMsg = 'Please select any folder to proceed.';
  static editLinkText = 'Edit Link';
  static cropWiseContentText = 'Crop Wise Content';
  static contentWiseSummaryText = 'Content Wise Summary';
  static cropWiseContentTimelinesText = 'Crop Wise Content Timelines';
  static completedText = 'Completed';
  static delayedText = 'Delayed';
  static notStartedText = 'Not Started';
  static noDataErrorText = 'No data available for selected values';
  static contentWiseSummaryandStatusText = 'Content Wise Summary and Status';
  static noDataFoundText = 'No data found.';
  static cropNameText = 'Crop Name';
  static editText = 'Edit';
  static publishText = 'Publish';
  static savePublishText = 'Save & Publish';
  static translateContentText = 'Translate content';
  static originalContentText = 'Original Content';
  static warningText = 'Warning';
  static selectValuesCautionText = 'Please select values to be scrapped from';
  static cropStageText = 'CropStage';
  static formFillCaution = 'Please fill the above required fields in the form';
  static process = 'Process';
  static noContentsFoundText = 'No Contents Found!';
  static processingText = 'Processing';
  static sendToReviewerText = 'Send to Reviewer';
  static commentBoxText = 'Add Comment';
  static notesText = 'Notes';
  static text = 'Text:';
  static pathText = 'Path:';
  static title = 'Title:';
  static contentTrackerText = 'Content Tracker';
  static recycleBinText = 'Recycle Bin';
  static createVariantText = 'Create Variant';
  static generateText = 'Generate';
  static emailErrorMsg = 'Please enter a valid Email Address';
  static browseText = 'Browse';
  static browserErrorMsg = 'Your browser does not support the video tag.';
  static phoneNumberErrorMsg = 'Please enter a valid Phone Number';
  static countryWithColon = 'Country:';
  static cropwithColon = 'Crop:';
  static contentTypeWithColon = 'Content Type:';
  static creatorName = 'Creator Name';
  static Name = 'Name';
  static submit = 'Submit';
  static dashboardMetricsText = 'Dashboard Metrics';
  static dashboardSummary = 'Summary';
  static dashboardContentInsight = 'Content Insight';
  static dashboardUserInsight = 'User Insight';
  static logoutWarningHeadingText = 'Logout Warning';
  static viewerRoleDisclaimer =
    'No crops configured to this Role. Please contact Administrator';
  static reopen = 'Reopen';
  static update = 'Update';
  static yesText = 'Yes';
  static noText = 'No';
  static updateOnAllVariant = 'Update on all variant';
  static selectAll = 'Select All';
  static changesCompleted = 'Changes Completed';
  static selectAttributeVariants =
    'Please select the attributes to update the value in variants.';
  static feedBack = 'FeedBack';
  static summaryHeading = 'Summary';
  static serverErrorMessage = 'Unable to fetch data from Analytics server';
  static englishContent = 'English Content';
  static contentHeading = 'Contents';
  static top = 'Top';
  static viewed = 'Viewed';
  static content = 'Content';
  static pageWise = 'Page Wise';
  static contentInsightTitle = 'Content Insight';
  static pagewiseHeading = 'Page Wise';
  static min = 'min';
  static dateUseValue = 'en-GB';
  static cacheLocation = 'localStorage';
  static pleaseSelect = 'Please select';
  static and = 'and';
  static fromFilter = 'from filter';
  static contentCreationInProgress = 'Please Wait!!! content creation in progress'
  static contentCreationFail = 'content creation failed'
  static contentCreationSuccess = 'content created successfully'
  static variantLoading = 'Please wait! variants are loading!'
  static noVariant = 'Variants are not published.Please publish it and try again!!'
  static unpublishText = 'Unpublish';
  static areYouSureWantToDeleteText = 'Are you sure want to delete?';
  static areYouSureWantToDeleteAllText = 'Are you sure want to delete all?';
  static areYouSureWantToUnpublishText = 'Are you sure want to Unpublish the';
  static formNotPublishedText = 'Form is not unpublished, Please try again';
  static contentType = [
    {
      id: 'Advisory',
      name: 'Advisory'
    },
    {
      id: 'FragmentedContent',
      name: 'FragmentedContent'
    },
    {
      id: 'BayerMedia',
      name: 'BayerMedia'
    },
    {
      id: 'SeedProduct',
      name: 'SeedProduct'
    },
    {
      id: 'CropProtectionProduct',
      name: 'CropProtectionProduct'
    },
    {
      id: 'Disease',
      name: 'Disease'
    },
    {
      id: 'Insect',
      name: 'Insect'
    },
    {
      id: 'Weed',
      name: 'Weed'
    },
    {
      id: 'Mappers',
      name: 'Mappers'
    }
  ];
  static glossaryText = 'Glossary:';
  static noGlossaryAvailableText = 'No glossary available for searched text';
  static createGlossaryText = 'CREATE GLOSSARY';
  static addToGlossary = 'Add to glossary';
}
