<div class="container">
  <div class="d-flex login-cont position-relative">
    <div>
      <img
        class="img-fluid bayer-logo"
        src="assets/images/Bayer-logo-transparent.png"
        alt="bayer-logo"
      />
    </div>

    <div class="img-holder">
      <div>
        <div class="white-stripe">
          <img src="/assets/icons/white-stripe.svg" alt="" />
        </div>

        <img class="login-bg" src="assets/images/login-bg.png" alt="" />

        <div class="blue-stripe">
          <img src="/assets/icons/blue-stripe.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="parent text-center">
      <div class="agrosmart text-center">
        <div>
          <img
            class="agro-logo img-fluid"
            src="assets/images/04.png"
            alt="agro"
          />

          <form class="mt-4 pb-4" #loginForm="ngForm">
            <div class="form-group">
              <input
                ngModel
                class="form-control"
                type="text"
                id="u_name"
                name="u_name"
                #u_name="ngModel"
                placeholder="Username"
                required
              />

              <small *ngIf="u_name.touched && u_name.errors?.required">{{
                userNameTouched
              }}</small>
            </div>

            <div class="form-group">
              <input
                ngModel
                class="form-control"
                type="password"
                id="pass"
                name="pass"
                #pass="ngModel"
                placeholder="Password"
                required
              />

              <small *ngIf="pass.touched && pass.errors?.required">{{
                passwordValidation
              }}</small>
            </div>

            <div class="lgn-btn">
              <button
                type="submit"
                (click)="externalLogin()"
                class="btn-bayer-primary b-body-18 w-100"
              >
                {{ externalLoginButton }}
              </button>
            </div>

            <div class="cwid-btn">
              <button
                type="submit"
                (click)="internalLogin()"
                class="btn-bayer-primary b-body-18 w-100"
              >
                {{ internalLoginButton }}
              </button>
            </div>
          </form>
        </div>

        <small class="copy-right"
          >{{ copyRightText }} © {{ bayerContentText }}</small
        >
      </div>
    </div>
    <div class="img-background"></div>
  </div>
</div>
<app-spinner></app-spinner>
